// import "react-app-polyfill/ie9";
import React, { Suspense } from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// import "./App.css";

import AboutState from "./context/about/AboutState";
import ConferencesState from "./context/conferences/ConferencesState";
import OffsetState from "./context/offset/OffestState";

const Routes = React.lazy(() => import("./routes"));

function App() {
    return (
        <OffsetState>
            <AboutState>
                <ConferencesState>
                    <Suspense fallback={<div>Loading</div>}>
                        <Routes />
                    </Suspense>
                </ConferencesState>
            </AboutState>
        </OffsetState>
    );
}

export default App;
