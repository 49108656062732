import { createMuiTheme } from "@material-ui/core/styles";

const defaultTheme = createMuiTheme({
    palette: {
        secondary: {
            main: "#75b2ad",
        },
    },
});

const { breakpoints } = defaultTheme;

const theme = {
    ...defaultTheme,
    overrides: {
        MuiCssBaseline: {
            "@global": {
                html: {
                    fontSize: 16,
                    [breakpoints.down("sm")]: {
                        fontSize: "0.875rem",
                    },
                    [breakpoints.down("xs")]: {
                        fontSize: "0.75rem",
                    },
                    WebkitFontSmoothing: "auto",
                    padding: 0,
                    margin: 0,
                },
                body: {
                    fontFamily: "'Fira Sans', sans-serif",
                    fontSize: 16,
                    backgroundColor: "#FFFFFF",
                    height: "100%",
                    minHeight: "100%",
                },
            },
        },
    },
};

export default theme;
