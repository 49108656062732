const GET_OFFSET_ABOUT = "GET_OFFSET_ABOUT";
const GET_OFFSET_CONFERENCES = "GET_OFFSET_CONFERENCES";
const GET_OFFSET_ARTICLES = "GET_OFFSET_ARTICLES";
const GET_OFFSET_CONTACT = "GET_OFFSET_CONTACT";
const SET_LOADING = "SET_LOADING";

  export default (state, action) => {
    switch (action.type) {
      case GET_OFFSET_ABOUT:
        return {
          ...state,
          about: action.payload,
        };
      case GET_OFFSET_CONFERENCES:
        return {
          ...state,
          conferences: action.payload,
        };
      case GET_OFFSET_ARTICLES:
        return {
          ...state,
          articles: action.payload,
        };
      case GET_OFFSET_CONTACT:
        return {
          ...state,
          contact: action.payload,
        };
      case SET_LOADING:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  };