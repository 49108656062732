import React, { useReducer } from "react";
import OffsetContext from "./offsetContext";
import OffsetReducer from "./offsetReducer";

const GET_OFFSET_ABOUT = "GET_OFFSET_ABOUT";
const GET_OFFSET_CONFERENCES = "GET_OFFSET_CONFERENCES";
const GET_OFFSET_ARTICLES = "GET_OFFSET_ARTICLES";
const GET_OFFSET_CONTACT = "GET_OFFSET_CONTACT";
const SET_LOADING = "SET_LOADING";

const OffsetState = (props) => {
    const initialState = {
        about: 0,
        conferences: 0,
        articles: 0,
        contact: 0,
        loading: false,
    };

    const [state, dispatch] = useReducer(OffsetReducer, initialState);

    const isMobile = window.innerWidth <= 600;

    const getOffset = (el) => {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY,
        };
    };

    // Get offest of about section
    const getOffsetAbout = (about) =>
        dispatch({ type: GET_OFFSET_ABOUT, payload: getOffset(about).top - (isMobile ? 75 : 150) });

    // Get offest of conferences section
    const getOffsetConferences = (conferences) =>
        dispatch({ type: GET_OFFSET_CONFERENCES, payload: getOffset(conferences).top });

    // Get offest of articles section
    const getOffsetArticles = (articles) => dispatch({ type: GET_OFFSET_ARTICLES, payload: getOffset(articles).top });

    // Get offest of contact section
    const getOffsetContact = (contact) => dispatch({ type: GET_OFFSET_CONTACT, payload: getOffset(contact).top });

    // Get offest of contact section
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <OffsetContext.Provider
            value={{
                about: state.about,
                conferences: state.conferences,
                articles: state.articles,
                contact: state.contact,
                loading: state.loading,
                getOffsetAbout,
                getOffsetConferences,
                getOffsetArticles,
                getOffsetContact,
                setLoading,
            }}
        >
            {props.children}
        </OffsetContext.Provider>
    );
};

export default OffsetState;
