const SET_OPEN = "SET_OPEN";
const SET_CONTENT = 'SET_CONTENT';

export default (state, action) => {
    switch (action.type) {
      case SET_OPEN:
        return {
          ...state,
          open: action.payload,
        };
      case SET_CONTENT:
        return {
          ...state,
          content: action.payload,
        };
      default:
        return state;
    }
  };