const SET_CURRENT_YEAR = "SET_CURRENT_YEAR";

export default (state, action) => {
    switch (action.type) {
        case SET_CURRENT_YEAR:
            return {
                ...state,
                currentYear: action.payload,
            };
        default:
            return state;
    }
};
