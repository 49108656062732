import React, { useReducer } from "react";
import ConferencesContext from "./conferencesContext";
import ConferencesReducer from "./conferencesReducer";

const SET_CURRENT_YEAR = "SET_CURRENT_YEAR";

const ConferencesState = (props) => {
  
    const initialState = {
        currentYear: null,//2018,

      };

    const [state, dispatch] = useReducer(ConferencesReducer, initialState);


    // Get offest of about section 
    const setCurrentYear = (year) => dispatch({ type: SET_CURRENT_YEAR, payload: year });


    return (
      <ConferencesContext.Provider
        value={{
          currentYear: state.currentYear,
          setCurrentYear
        }}
      >
        {props.children}
      </ConferencesContext.Provider>
    );
  };

export default ConferencesState;