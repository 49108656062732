import React, { useReducer } from "react";
import AboutContext from "./aboutContext";
import AboutReducer from "./aboutReducer";

const SET_OPEN = "SET_OPEN";
const SET_CONTENT = 'SET_CONTENT';

const AboutState = (props) => {
  
    const initialState = {
        open: false,
        content: 'statute'
      };

    const [state, dispatch] = useReducer(AboutReducer, initialState);

    // Set open about section
    const setOpen = (bool) => dispatch({ type: SET_OPEN, payload: bool });

    // Set open about section
    const setContent = (section) => dispatch({ type: SET_CONTENT, payload: section });

    return (
      <AboutContext.Provider
        value={{
            open: state.open,
            content: state.content,
            setOpen,
            setContent
        }}
      >
        {props.children}
      </AboutContext.Provider>
    );
  };

export default AboutState;